<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Add Agent
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Type *</p>
            <v-select
              label="Select Type"
              single-line
              outlined
              v-model="type"
              :items="types"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Reference ID *</p>
            <v-text-field
              label="Reference ID"
              single-line
              outlined
              v-model="ref_id"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Name *</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Email *</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 class="pa-3">
            <p class="mb-1 uppercase primary--text">Code *</p>
            <v-select
              label="Code"
              single-line
              outlined
              v-model="code"
              :items="countryCodes"
            ></v-select>
          </v-flex>
          <v-flex xs12 md5 lg5 class="pa-3">
            <p class="mb-1 uppercase primary--text">Phone *</p>

            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md5 lg5 class="pa-3">
            <p class="mb-1 uppercase primary--text">Alt. Phone</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone_alt"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Fax</p>
            <v-text-field
              label="Fax"
              single-line
              outlined
              v-model="fax"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Website</p>
            <v-text-field
              label="Website"
              single-line
              outlined
              v-model="website"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Registration Address *</p>
            <v-textarea
              label="Registration Address"
              single-line
              outlined
              v-model="address"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">City *</p>
            <v-text-field
              label="City"
              single-line
              outlined
              v-model="city"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Country *</p>
            <v-select
              label="Country"
              single-line
              item-text="country"
              outlined
              :items="countryList"
              v-model="country"
            ></v-select>
          </v-flex>
          <v-flex
            xs12
            md6
            lg6
            class="pa-3"
            v-if="country == 'United Arab Emirates'"
          >
            <p class="mb-1 uppercase primary--text">Emirate *</p>
            <v-select
              label="Emirate"
              single-line
              outlined
              :items="emirates"
              v-model="emirate"
            ></v-select>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>

          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">VAT / TRN</p>
            <v-text-field
              type="number"
              label="VAT/TRN"
              single-line
              outlined
              v-model="vat"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">CORP Tax</p>
            <v-text-field
              label="Corp Tax"
              single-line
              outlined
              v-model="trn"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Commission (%) *</p>
            <v-text-field
              label="Commission"
              single-line
              outlined
              suffix="%"
              v-model="commission"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Payment Mode *</p>
            <v-select
              label="Direct / Credit"
              single-line
              outlined
              :items="paymentModes"
              v-model="payment_mode"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 class="pa-3" v-if="payment_mode == 'Credit'">
            <p class="mb-1 uppercase primary--text">Credit Limit</p>
            <v-text-field
              label="Credit Limit"
              single-line
              outlined
              v-model="credit"
              suffix="AED"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3" v-if="payment_mode == 'Credit'">
            <p class="mb-1 uppercase primary--text">Payment Interval</p>
            <v-text-field
              label="Payment Interval"
              single-line
              outlined
              v-model="payment_interval"
              suffix="Days"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>
          <v-flex xs12 md12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Copy of Trade License *</p>
            <v-file-input
              single-line
              outlined
              v-model="selectedFile"
              label="Choose a file"
              @change="updateFileName"
            ></v-file-input>
          </v-flex>
          <v-flex xs4 class="pa-3 mt-5">
            <v-btn
              large
              block
              class="primary black--text"
              @click="createAgentAccount"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog v-model="dialogUserAccount" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Agent Added
        </v-card-title>
        <v-card-text>
          <p class="mt-5">
            Please save or download the password for this account. You will not
            be able to view the view this password again.
          </p>
          <div
            class="pa-3"
            style="
              border: 1px solid #424242;
              border-radius: 5px;
              margin-top: 10px;
            "
          >
          <p class="accent--text">{{ agentAccount.name }}</p>
          <p class="accent--text">{{ agentAccount.email }}</p>
          <p class="accent--text">{{ agentAccount.password }}</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-clipboard:copy="agentAccount"> Copy </v-btn>
          <v-btn color="primary" text @click="downloadCSV(agentAccount)"> Download </v-btn>
          <v-btn color="primary" text @click="dialogUserAccount = false"> CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-layout>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import { countries } from "@/country-codes";
import Axios from "axios";
import VueClipboard from 'vue-clipboard2';

export default {
  data() {
    return {
      type: "Individual",
      types: ["Individual", "Organisation"],
      ref_id: "",
      name: "",
      code: "+971",
      phone: "",
      phone_alt: "",
      fax: "",
      email: "",
      address: "",
      city: "",
      country: "United Arab Emirates",
      emirate: "",
      website: "",
      vat: "",
      trn: "",
      commission: "",
      payment_mode: "",
      paymentModes: ["Credit", "Direct Payment"],
      credit: "",
      payment_interval: "14",
      selectedFile: null,
      emirates: [
        "Abu Dhabi",
        "Ajman",
        "Dubai",
        "Fujairah",
        "Ras Al Khaimah",
        "Sharjah",
        "UAQ",
      ],
      dialogUserAccount : false ,
      password : "",
      agentAccount : {}
    };
  },
  mounted() {
    VueClipboard.config.autoSetContainer = true;
    this.$nextTick(() => {
      this.$root.$clipboard = new VueClipboard();
    });
  },
  methods: {
    async createAccount(name,email,phone,id) {
      this.password = this.generatePassword()
      let url = BASE_URL + "/admin";
      let payload = {
        name: name,
        email: email,
        phone: phone,
        role: "Agent",
        profileType: "agent",
        profile: id,
        password: this.password,
      };
      this.agentAccount = {
        name : name,
        email : email,
        password : this.password
      }
      let { data } = await Axios.post(url, payload);
      this.dialogUserAccount = true
    },
    downloadCSV(agentAccount) {
      var _acc = [
        agentAccount
      ]
      const csvContent = this.convertToCSV(_acc);
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.agentAccount.name + ".csv";
      link.click();
    },
    convertToCSV(data) {
      const header = Object.keys(data[0]).join(",");
      const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
      return `${header}\n${rows}`;
    },
    copyToClipBoard(){
      this.$clipboard
        .copy(this.agentAccount.toString())
        .then(() => {
          alert("Copied!")
        })
        .catch((error) => {
          alert("Cannot Copy")
        });
    },
    generateRandomID() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    generatePassword(length = 12) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let password = '';
      for (let i = 0; i < length; i++) {
          password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    },
    async createAgentAccount() {
      let url = BASE_URL + "/agent";
      let payload = {
        type: this.type,
        ref_id: this.ref_id,
        name: this.name,
        email: this.email,
        country_code: this.code,
        phone: this.phone,
        phone_alt: this.phone_alt,
        fax: this.fax,
        website: this.website,
        address: this.address,
        city: this.city,
        country: this.country,
        emirate: this.emirate,
        vat: this.vat,
        trn: this.trn,
        payment_mode : this.payment_mode,
        commission: this.commission,
        payment_interval: this.payment_interval,
        credit: this.credit,
      };
      if (
        this.name == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.ref_id == "" ||
        this.address == "" ||
        this.city == "" ||
        this.commission == "" ||
        this.payment_mode == ""
      ) {
        this.$toastr.e(
          "Please enter mandatory fields to create the account.",
          "Missing Data"
        );
      } else {
        let { data } = await Axios.post(url, payload);
        this.createAccount(this.name,this.email,this.code+this.phone,data.user._id)
        this.type = "Individual";
        this.ref_id = this.generateRandomID();
        this.name = "";
        this.email = "";
        this.code = "+971";
        this.phone = "";
        this.phone_alt = "";
        this.fax = "";
        this.website = "";
        this.address = "";
        this.city = "";
        this.country = "United Arab Emirates";
        this.emirate = "";
        this.vat = "";
        this.trn = "";
        this.commission = "";
        this.payment_interval = "14";
        this.credit = "";
        this.selectedFile = null;
        this.$toastr.s("New account created successfully", "Agent Added");
      }
    },
  },
  computed: {
    countryList() {
      return countries;
    },
    countryCodes() {
      return this.countryList.map((country) => country.code).sort();
    },
  },
};
</script>

<style>
.v-text-field__suffix {
  color: #ba9d73 !important;
}
</style>